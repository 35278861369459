import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null || localStorage.getItem('tokens')
  },
  mutations: {
    gettoken(state, payload){
      state.token = payload;
      localStorage.setItem('tokens', payload);
    }
    
  },
  actions: {
  },
  modules: {
  }
})
