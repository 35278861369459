import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
Vue.use(VueRouter)
const routes = [
    {
      path: '/',
      redirect: "/homePages",
      meta: {
        title: '首页'
      },
      component: () => import('../views/homePages.vue')
    },
    {
        path: '/homePages',
        meta: {
          title: '首页'
        },
        component: () => import('../views/homePages.vue')
      },
    {
        path: '/searchDoctor',
        meta: {
          title: '找医生'
        },
        component: () => import('../views/searchDoctor.vue')
      },
      {
        path: '/hospitalView',
        meta: {
          title: '医院详情'
        },
        component: () => import('../views/hospitalView')
      },
    {
        path: '/searchHospital',
        meta: {
          title: '找医院'
        },
        component: () => import('../views/searchHospital.vue')
      },
    {
        path: '/doctorView',
        meta: {
          title: '找医院'
        },
        component: () => import('../views/doctorView.vue')
      },
    {
        path: '/expertVideo',
        meta: {
          title: '专家视频'
        },
        component: () => import('../views/expertVideo.vue')
      },
    {
        path: '/videoDetails',
        meta: {
          title: '视频详情'
        },
        component: () => import('../views/videoDetails.vue')
      },
    {
        path: '/articleView',
        meta: {
          title: '科普文章'
        },
        component: () => import('../views/articleView.vue')
      },
    {
        path: '/detailsView',
        meta: {
          title: '文章详情'
        },
        component: () => import('../views/detailsView.vue')
      },
    {
        path: '/diseaseView',
        meta: {
          title: '疾病问答'
        },
        component: () => import('../views/diseaseView.vue')
      },
    {
        path: '/computerPages',
        meta: {
          title: '打开错误'
        },
        component: () => import('../views/computerPages.vue')
      },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  
  router.beforeEach((to, from, next) => {
      if(to.query && to.query.token){
       let token = {
         token: to.query.token,
         device: to.query.device || ''
       }
       store.commit('gettoken',JSON.stringify(token))
      }
    document.title = '薛医生-' + to.meta.title
    next()
  })
  
  
  
  const originalPush = VueRouter.prototype.push;
  const originalreplace = VueRouter.prototype.replace;
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
  }
  VueRouter.prototype.replace = function replace(location) {
    return originalreplace.call(this, location).catch(err => err);
  }
  export default router